/* eslint-disable react/no-unescaped-entities */
import React from 'react';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

const mock = [

  // {
    
  //   description:
  //     'Childrens Day',
  //   href : '/childrens-day-1819'
 
  // },

  
  {
    
    description:
      'Class Assembly 1A',
    href : '/class-assembly-1a'
 
  },
  
  {
    
    description:
      'Class Assembly 1B',
    href : '/class-assembly-1b'
 
  },
  {
    
    description:
      'Class Assembly 1C',
    href : '/class-assembly-1c'
 
  },
  {
    
    description:
      'Class Assembly 1D',
    href : '/class-assembly-1d'
 
  },
  {
    
    description:
      'Class Assembly 1E',
    href : '/class-assembly-1e'
 
  },
  {
    
    description:
      'Class Assembly 2A',
    href : '/class-assembly2a'
 
  },
 
  {
    
    description:
      'Class Assembly 2B',
    href : '/class-assembly-2b'
 
  },
  {
    
    description:
      'Class Assembly 2C',
    href : '/class-assembly-2c'
 
  },
  {
    
    description:
      'Class Assembly 2D',
    href : ' /class-assembly-2d'
 
  },
  {
    
    description:
      'Class Assembly 2E',
    href : '  /class-assembly-2e'
 
  },

 
  {
    
    description:
      'Class Assembly 3A',
    href : '/class-assembly-3a'
 
  },
  {
    
    description:
      'Class Assembly 3B',
    href : '/class-assembly-3b'
 
  },  
  {
    
    description:
      'Class Assembly 3C',
    href : '/class-assembly3c'
 
  },  
 
  {
    
    description:
      'Class Assembly 3D',
    href : '/class-assembly-3d'
 
  },
  {
    
    description:
      'Class Assembly 3E',
    href : '/class-assembly-3e'
 
  }, 
  {
    
    description:
      'Class Assembly 3F',
    href : '/class-assembly3f'
 
  }, 
  {
    
    description:
      'Class Assembly 4A',
    href : '/class-assembly4a'
 
  },
  {
    
    description:
      'Class Assembly 4B',
    href : '/class-assembly4b'
 
  },  
  {
    
    description:
      'Class Assembly 4C',
    href : '/class-assembly4c'
 
  },  
  {
    
    description:
      'Class Assembly 4D',
    href : '/class-assembly-4d'
 
  },  
  {
    
    description:
      'Class Assembly 4E',
    href : '/class-assembly-4e'
 
  },  
  {
    
    description:
      'Class Assembly 4F',
    href : '/class-assembly-4f'
 
  },
  {
    
    description:
      'Class Assembly 5A',
    href : '/class-assembly5a'
 
  },
  {
    
    description:
      'Class Assembly 5B',
    href : '/class-assembly5b'
 
  },
  {
    
    description:
      'Class Assembly 5C',
      href : '/class-assembly5c'
 
 
  },
  {
    
    description:
      'Class Assembly 5D',
      href : '/class-assembly5d'
 
 
  },
  {
    
    description:
      'Class Assembly 5E',
      href : '/class-assembly5e'
 
 
  },
  {
    
    description:
      'Class Assembly 6A',
      href : '/class-assembly-6a'
 
 
  },
  {
    
    description:
      'Class Assembly 6B',
      href : '/class-assembly-6b'
 
 
  },

  {
    
    description:
      'Class Assembly 6C',
      href : '/class-assembly-6c'
 
 
  },

  {
    
    description:
      'Class Assembly 6D',
      href : '/class-assembly-6d'
 
 
  },
  {
    
    description:
      'Class Assembly 6E',
      href : '/class-assembly-6e'
 
 
  },
  {
    
    description:
      'Class Assembly 7A',
      href : '/class-assembly-7a'
 
 
  },
  {
    
    description:
      'Class Assembly 7B',
    
 
 
  },
  
  {
    
    description:

      'Class Assembly 7C',
      href : '/class-assembly-7c'
 
 
  },
  {
    
    description:
      'Class Assembly 7D',
      href : '/class-assembly-7d'
 
 
  },
  {
    
    description:
      'Class Assembly 7E',
      href : '/class-assembly-7e'
 
 
  },

  {
    
    description:
      'Class Assembly 8A',
      href : '/class-assembly8a'
 
  },
  {
    
    description:
      'Class Assembly 8B',
      href : '/class-assembly8b'
 
 
  },
  {
    
    description:
      'Class Assembly 8C',
      href : '/class-assembly8c'
 
 
  },

  {
    
    description:
      'Class Assembly 8D',
      href : '/class-assembly-8d'
 
 
  },
  {
    
    description:
      'Class Assembly 8E',
      href : '/class-assembly-8e'
 
 
  },
  
  
  {
    
    description:
      'Class Assembly 9A',
      href : '/class-assembly9a'
 
 
  },
  {
    
    description:
      'Class Assembly 9B',
      href : '/class-assembly9b'
 
 
  },  {
    
    description:
      'Class Assembly 9C',
      href : '/class-assembly9c'
 
 
  },  {
    
    description:
      'Class Assembly 9D',
      href : '/class-assembly9d'
 
 
  },  {
    
    description:
      'Class Assembly 9E',
      href : '/class-assembly9e'
 
 
  },  
  
  {
    
    description:
      'Class Assembly 10A',
      href : '/class-assembly10a'
 
 
  },
  {
    
    description:
      'Class Assembly 10B',
      href : '/class-assembly10b'
 
 
  },
  {
    
    description:
      'Class Assembly 10C',
      href : '/class-assembly-10c'
 
 
  },
  {
    
    description:
      'Class Assembly 10D',
      href : '/class10d'
 
 
  },
  {
    
    description:
      'Class Assembly 11A',
      href : '/class-assembly11a'
 
 
  },  {
    
    description:
      'Class Assembly 11B',
      href : '/class-assembly11b'
 
 
  },  {
    
    description:
      'Class Assembly 11C',
      href : '/class-assembly11c'
 
 
  },
  {
    
    description:
      'Class Assembly 12A',
      href : '/class-assembly-12a'
 
 
  },  
  {
    
    description:
      'Class Assembly 12B',
      href : '/class-assembly-12b'
 
 
  },  
  {
    
    description:
      'Class Assembly 12C',
      href : '/class-assembly-12c'
 
 
  },
 
  // {
  //   image: 'https://assets.maccarianagency.com/backgrounds/img16.jpg',
  //   description:
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  //   title: 'Consectetur adipiscing elit',
  //   author: {
  //     name: 'Jhon Anderson',
  //   },
  //   date: '12 Sep',
  // },
];

const SidebarArticles = () => {
  
  return (
    <Box component={Card} variant={'outlined'} padding={2}>
      <Typography
        variant="h6"
        data-aos={'fade-up'}
        sx={{
          fontWeight: 700,
          marginBottom: 2,
        }}
      >
        Class Assembly
      </Typography>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12}>
            <Box
              component={Card}
              width={1}
              height={1}
              boxShadow={0}
              borderRadius={0}
              display={'flex'}
              flexDirection={{ xs: 'column', md: 'row' }}
              sx={{ backgroundImage: 'none', bgcolor: 'transparent' }}
            >
              <Button href = {item.href}>
                <Typography component={'P'}>
                  {item.description}
                </Typography>
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SidebarArticles;
